import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/webplikasi.png";
import navIcon1 from "../assets/img/yt.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from '../assets/img/wa.png';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" style={{ marginTop: "50px" }} />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon" style={{ marginTop: "50px" }}>
              <a href="https://www.youtube.com/@aji_sanjaya/"><img src={navIcon1} alt="" /></a>
              <a href="https://www.facebook.com/herno.aji.sanjaya/"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/aji__sanjaya/"><img src={navIcon3} alt="" /></a>
              <a href="https://wa.me/628128880646"><img src={navIcon4} alt="" /></a>
            </div>
            <p>Thank you for attention</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
